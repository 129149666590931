// @flow
import * as React from 'react'
import consecutive from 'consecutive'
import styled, { ThemeContext } from 'styled-components'
import { rem } from 'polished'

import type { FooterContactViewModel } from '../types/FooterContactViewModel'
import { effects, color } from '../../../styles/theme'

import { Link } from '../../../components/Link/Link'
import Heading from '../../../components/Heading/Heading'
import AccordionContainer from '../../../components/AccordionElements/AccordionContainer'
import AccordionItem from '../../../components/AccordionElements/AccordionItem'
import { getFontSize, getLineHeight } from '../../../styles/style-helpers'
import { mediaQuery } from '../../../styles/media'

type Props = {
  contact?: FooterContactViewModel,
}

const FooterContactWrapper = styled.section`
  display: flex;
  flex-direction: column;
  ${mediaQuery.xs`
    margin-bottom: ${rem(48)};
  `};
  ${mediaQuery.md`
    margin-bottom: ${rem(48)};
  `};
`

const FooterContactTitle = styled(Heading).attrs({
  tag: 'h3',
  invert: true,
  level: 4,
})`
  margin-bottom: ${rem(16)};
`

const FooterContactItemTitle = styled(Heading).attrs({
  tag: 'h4',
  invert: true,
  level: 5,
})`
  && {
    text-transform: uppercase;
    line-height: ${getLineHeight('small')};
    letter-spacing: 1px;
    opacity: 0.75;
  }
`

const FooterContactItem = styled.div`
  border-bottom: ${({ theme }) => effects(theme).borderNegative};
  padding-bottom: ${rem(16)};

  &:not(:last-child) {
    margin-bottom: ${rem(48)};
  }
`

const FooterContactPhone = styled(Link)`
  font-size: ${rem(44)};
  font-weight: 900;
  line-height: ${getLineHeight('tiny')};

  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.textInvert};

  &:hover {
    color: ${({ fontColor, theme }) =>
      !fontColor ? theme.phoneTextColorHover : theme.phoneTextAccentColorHover};
  }
`

const FooterContactOpeningHoursWrapper = styled.div`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(8)};
`

const FooterContactOpeningHours = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${getFontSize('tiny')};
  opacity: 0.64;
  line-height: ${getLineHeight('large')};
  color: ${({ theme }) => theme.textInvert};
`

const FooterContactOpeningHoursLabel = styled.div`
  text-align: left;
`

const FooterContactOpeningHoursValue = styled.div`
  text-align: right;
`

const keyGen = consecutive()

const FooterContact = ({ contact }: Props) => {
  const theme = React.useContext(ThemeContext)

  return (
    <FooterContactWrapper>
      {contact && contact.title && (
        <FooterContactTitle>{contact.title}</FooterContactTitle>
      )}
      {contact &&
        contact.items &&
        contact.items.map((item, index) => {
          const fontColor =
            item && item.accentPhone ? theme && theme.accent : undefined

          return (
            <FooterContactItem key={`FooterContactItem-${keyGen()}`}>
              {item.title && (
                <FooterContactItemTitle>{item.title}</FooterContactItemTitle>
              )}
              {item.phone && (
                <FooterContactPhone {...item.phone} fontColor={fontColor}>
                  {item.phone.label}
                </FooterContactPhone>
              )}
              {item.currentOpeningHours && item.openingHours && (
                <React.Fragment>
                  <AccordionContainer>
                    <AccordionItem
                      isOnFooter
                      prefix="footer-contact"
                      invert={true}
                      key={`FooterContactAccordionItem-${keyGen()}`}
                      id={`${index}`}
                      title={
                        item &&
                        item.currentOpeningHours &&
                        item.currentOpeningHours.isOpen
                          ? `${item.currentOpeningHours.isOpenTitle ||
                              ''} ${item.currentOpeningHours.openingHours ||
                              ''}`
                          : `${(item &&
                              item.currentOpeningHours &&
                              item.currentOpeningHours.notOpenTitle) ||
                              ''}`
                      }
                      iconStyle={{ color: color.white }}
                      titleStyle={{
                        paddingTop: rem(8),
                        paddingBottom: rem(8),
                        border: '0 none',
                      }}
                      titleInnerStyle={{ fontWeight: 500 }}
                      itemBodyStyle={{ border: '0 none' }}
                    >
                      <FooterContactOpeningHoursWrapper>
                        {item.openingHours.map(openingHours => (
                          <FooterContactOpeningHours
                            key={`FooterContactOpeningHours-${keyGen()}`}
                          >
                            <FooterContactOpeningHoursLabel>
                              {openingHours.day}
                            </FooterContactOpeningHoursLabel>
                            <FooterContactOpeningHoursValue>
                              {openingHours.hours}
                            </FooterContactOpeningHoursValue>
                          </FooterContactOpeningHours>
                        ))}
                      </FooterContactOpeningHoursWrapper>
                    </AccordionItem>
                  </AccordionContainer>
                </React.Fragment>
              )}
            </FooterContactItem>
          )
        })}
    </FooterContactWrapper>
  )
}

export default FooterContact
