// @flow
import * as React from 'react'
import consecutive from 'consecutive'
import styled from 'styled-components'
import { rem } from 'polished'

import type { FooterNavigationViewModel } from '../types/FooterNavigationViewModel'
import Heading from '../../../components/Heading/Heading'
import LinkList from '../../../components/LinkList/LinkList'
import { color } from '../../../styles/theme'
import { Link } from '../../../components/Link/Link'
import Image from '../../../components/Image/Image'

type Props = {
  navigation?: FooterNavigationViewModel,
}

const keyGen = consecutive()

const FooterNavigationDesktopWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: ${color.white};
  }

  :hover {
    color: ${({ theme }) => theme.logoTextColorHover};
  }
`

const FooterNavigationDesktopColumn = styled.div`
  flex-basis: 50%;
`

const FooterNavigationDesktopColumnTitle = styled(Heading).attrs({
  tag: 'h3',
  invert: true,
  level: 4,
})`
  font-weight: 900;
`

const FooterNavigationDesktopColumnItem = styled.div`
  &:not(:first-child) {
    margin-top: ${rem(56)};
  }
`
export const StyledLogoLinks = styled(Link)`
  display: block;
  margin-bottom: ${rem(24)};
  > :last-of-type {
    margin-bottom: 0;
  }
`

const FooterNavigationDesktop = ({ navigation }: Props) => {
  return (
    <FooterNavigationDesktopWrapper>
      <FooterNavigationDesktopColumn>
        {navigation &&
          navigation.column1 &&
          navigation.column1.map(column => (
            <FooterNavigationDesktopColumnItem
              key={`FooterNavigationDesktopColumn-1-${keyGen()}`}
            >
              <FooterNavigationDesktopColumnTitle>
                {column.title}
              </FooterNavigationDesktopColumnTitle>
              <LinkList links={column.links} />
            </FooterNavigationDesktopColumnItem>
          ))}
      </FooterNavigationDesktopColumn>
      <FooterNavigationDesktopColumn>
        {navigation &&
          navigation.column2 &&
          navigation.column2.map(column => (
            <FooterNavigationDesktopColumnItem
              key={`FooterNavigationDesktopColumn-2-${keyGen()}`}
            >
              <FooterNavigationDesktopColumnTitle>
                {column.title}
              </FooterNavigationDesktopColumnTitle>
              <LinkList links={column.links} />
            </FooterNavigationDesktopColumnItem>
          ))}
      </FooterNavigationDesktopColumn>
      {navigation && navigation.column3 && (
        <FooterNavigationDesktopColumn>
          {navigation.column3.map(column => (
            <FooterNavigationDesktopColumnItem
              key={`FooterNavigationDesktopColumn-3-${keyGen()}`}
            >
              <FooterNavigationDesktopColumnTitle>
                {column.title}
              </FooterNavigationDesktopColumnTitle>
              <LinkList links={column.links} />
            </FooterNavigationDesktopColumnItem>
          ))}
        </FooterNavigationDesktopColumn>
      )}
    </FooterNavigationDesktopWrapper>
  )
}

export default FooterNavigationDesktop
