// @flow
import * as React from 'react'
import consecutive from 'consecutive'

import type { LinkViewModel } from '../../../types/LinkViewModel'
import styled from 'styled-components'
import { Link } from '../../../components/Link/Link'
import { mediaOnly } from '../../../styles/media'
import { rem } from 'polished'
import { useAtom } from 'jotai'
import { showCookieDisclaimerAtom } from '../../../App/Atoms'

type Props = {
  services?: Array<LinkViewModel>,
}

const FooterServicesWrapper = styled.section`
  display: flex;
  margin-top: ${rem(64)};
  flex-direction: row;
  ${mediaOnly.xs`
    flex-direction: column;
    margin-top: 0;
    padding-top: ${rem(24)};
  `};
`

const FooterServiceLink = styled(Link)`
  line-height: 2.86;
  opacity: 0.64;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-stretch: normal;
  margin-right: ${rem(48)};
  ${mediaOnly.xs`
    margin-right: 0;
  `};
`

const keyGen = consecutive()

const FooterServices = ({ services }: Props) => {
  const [, setShowCookies] = useAtom(showCookieDisclaimerAtom)
  return (
    <FooterServicesWrapper>
      {services &&
        services.map(service => (
          <FooterServiceLink
            {...service}
            invert
            key={`FooterServices-${keyGen()}`}
            onClick={e => {
              if (service.href === '#cookies') {
                e.preventDefault()
                setShowCookies(true)
              }
            }}
          >
            {service.label}
          </FooterServiceLink>
        ))}
    </FooterServicesWrapper>
  )
}

export default FooterServices
