// @flow
import * as React from 'react'
import styled from 'styled-components'
import consecutive from 'consecutive'

import type { FooterNavigationViewModel } from '../types/FooterNavigationViewModel'

import AccordionContainer from '../../../components/AccordionElements/AccordionContainer'
import AccordionItem from '../../../components/AccordionElements/AccordionItem'
import { color, effects } from '../../../styles/theme'
import LinkList from '../../../components/LinkList/LinkList'
import { rem } from 'polished'
import useId from '@charlietango/use-id'

type Props = {
  navigation?: FooterNavigationViewModel,
}

const FooterNavigationDeviceWrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const FooterNavigationDeviceSection = styled.div`
  border-bottom: ${({ theme }) => effects(theme).borderNegative};
`

const FooterNavigationDeviceSpacer = styled.div`
  margin-bottom: ${rem(32)};
`

const keyGen = consecutive()

const FooterNavigationDevice = ({ navigation }: Props) => {
  const uniqueId = useId()
  const column1 = (navigation && navigation.column1) || []
  const column2 = (navigation && navigation.column2) || []
  const column3 = (navigation && navigation.column3) || []
  const columns = [...column1, ...column2, ...column3]

  return (
    <FooterNavigationDeviceWrapper>
      <FooterNavigationDeviceSection key={`FooterNavigationDevice-${keyGen()}`}>
        <AccordionContainer>
          {columns.map((column, index) => (
            <AccordionItem
              key={`FooterNavigationDeviceAccordionItem-${keyGen()}`}
              prefix="footer-device-nav"
              id={`${uniqueId}-${index}`}
              title={column.title || ''}
              iconStyle={{ color: color.white }}
              itemBodyStyle={{ borderBottom: '0 none' }}
              titleStyle={{ borderBottom: '0 none' }}
              invert={true}
            >
              <FooterNavigationDeviceSpacer>
                <LinkList links={column.links} invert={true} />
              </FooterNavigationDeviceSpacer>
            </AccordionItem>
          ))}
        </AccordionContainer>
      </FooterNavigationDeviceSection>
    </FooterNavigationDeviceWrapper>
  )
}

export default FooterNavigationDevice
