// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { FooterViewModel } from './types/FooterViewModel'

import FooterNavigationDesktop from './FooterNavigationDesktop/FooterNavigationDesktop'
import FooterNavigationDevice from './FooterNavigationDevice/FooterNavigationDevice'
import FooterContact from './FooterContact/FooterContact'
import FooterServices from './FooterServices/FooterServices'
import { color } from '../../styles/theme'
import { mediaQuery } from '../../styles/media'
import MediaQuery from '../../components/MediaQuery/MediaQuery'
import Ribbon from '../../components/Ribbon/Ribbon'

type Props = FooterViewModel

const FooterWrapper = styled.footer`
  grid-area: footer;
  z-index: 1;
  background-color: ${({ theme }) => theme.primary};
  color: ${color.white};
  padding-top: ${rem(96)};
  padding-bottom: ${rem(48)};
  ${mediaQuery.xs`
    padding-top: ${rem(64)};
  `};
`

const FooterDesktopWrapper = styled.div`
  display: none;
  flex-direction: row;
  ${mediaQuery.md`
    display: flex;
  `};
`

const FooterDeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQuery.md`
    display: none;
  `};
`

const FooterWrapperDesktopNavigation = styled.div`
  flex-grow: 2;
`

const FooterWrapperDesktopContact = styled.div`
  flex-grow: 1;
`

function Footer({ navigation, contact, services }: Props) {
  return (
    <FooterWrapper>
      <Ribbon marginBottom={false}>
        <MediaQuery query={`(min-width: 1025px)`}>
          <FooterDesktopWrapper>
            <FooterWrapperDesktopNavigation>
              <FooterNavigationDesktop navigation={navigation} />
            </FooterWrapperDesktopNavigation>
            {contact && (
              <FooterWrapperDesktopContact>
                <FooterContact contact={contact} />
              </FooterWrapperDesktopContact>
            )}
          </FooterDesktopWrapper>
        </MediaQuery>
        <MediaQuery query={`(max-width: 1024px)`}>
          <FooterDeviceWrapper>
            {contact && <FooterContact contact={contact} />}
            <FooterNavigationDevice navigation={navigation} />
          </FooterDeviceWrapper>
        </MediaQuery>
        <FooterServices services={services} />
      </Ribbon>
    </FooterWrapper>
  )
}

export default Footer
