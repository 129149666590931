// @flow
import * as React from 'react'
import consecutive from 'consecutive'
import styled from 'styled-components'

import type { LinkViewModel } from '../../types/LinkViewModel'

import { Link } from '../Link/Link'
import { getLineHeight } from '../../styles/style-helpers'
import { rem } from 'polished'

type Props = {
  links?: Array<LinkViewModel>,
  style?: Object,
  invert?: boolean,
}

const keyGen = consecutive()

const LinkListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const LinkListItem = styled.li`
  margin: 0 0 ${rem(8)} 0;
  padding: 0;
`

const LinkListLink = styled(Link)`
  font-weight: 500;
  font-stretch: normal;
  line-height: ${getLineHeight('normal')};
  letter-spacing: normal;
  white-space: nowrap;
`

const LinkList = ({ links, style, invert }: Props) => {
  style || (style = {})

  return (
    <LinkListWrapper>
      {links &&
        links.map(link => (
          <LinkListItem key={`LinkListItem-${keyGen()}`} invert={invert}>
            <LinkListLink {...link} style={style} invert={invert}>
              {link.label}
            </LinkListLink>
          </LinkListItem>
        ))}
    </LinkListWrapper>
  )
}

LinkList.displayName = 'LinkList'
LinkList.defaultProps = {}

export default LinkList
